import s from './DeleteHeader.module.scss';
import Settings from '@/shared/assets/icons/settings.svg';

export const DeleteHeader = () => {
    return (
        <div className={s.DeleteHeader}>
            <Settings className={s.icon} />
        </div>
    );
};
