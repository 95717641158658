import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClubTableItem } from '@/shared/model/types/table';

interface ClubTableState {
    selectedArray: ClubTableItem[];
}

const initialState: ClubTableState = {
    selectedArray: [],
};

export const clubTableSlice = createSlice({
    name: 'clubTable',
    initialState,
    reducers: {
        setSelectedArray: (state, action: PayloadAction<ClubTableItem[]>) => {
            state.selectedArray = action.payload;
        },
    },
});

export const { setSelectedArray } = clubTableSlice.actions;

export const { reducer: clubTableReducer } = clubTableSlice;
