import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { Cookies } from 'react-cookie';
import { setIsAuth, setIsAuthLoading } from '@/app/model/slice/appSlice';

const mutex = new Mutex();
const cookies = new Cookies();

const baseQuery = fetchBaseQuery({
    baseUrl: __API__,
    prepareHeaders: (headers) => {
        const token =
            cookies.get('token') || sessionStorage.getItem('token') || '';
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const customFetchBase: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
        api.dispatch(setIsAuth(false));
        api.dispatch(setIsAuthLoading(false));
    }

    if (result.error?.status !== 401) {
        api.dispatch(setIsAuth(true));
        api.dispatch(setIsAuthLoading(false));
    }

    return result;
};

export default customFetchBase;
