import s from './SelectedPopup.module.scss';
import { UnbindingHWIDButton } from '@/features/UnbindingHWID';
import { DeleteAccountButton } from '@/features/DeleteAccount';
import { GeneralSelectedI } from '@/shared/model/types/generalSelected';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { DraggableButton } from '@/features/DraggableButton';
import { Portal } from '@/shared/ui/Portal/Portal';
import { useRef } from 'react';

interface SelectedPopupProps extends GeneralSelectedI {
    resetSelectedRows?: () => void;
}

export const SelectedPopup = (props: SelectedPopupProps) => {
    const { arrayOfName, arrayOfId, resetSelectedRows } = props;
    const popupRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useScreenDetector();

    return (
        <Portal>
            <div className={s.SelectedPopup} ref={popupRef}>
                {isMobile ? (
                    <div className={s.header}>
                        <span className={s.info}>
                            Выбрано записей: {arrayOfId.length}
                        </span>
                        <DraggableButton popupRef={popupRef} />
                    </div>
                ) : (
                    <span className={s.info}>
                        Выбрано записей: {arrayOfId.length}
                    </span>
                )}

                <div className={s.actions}>
                    <UnbindingHWIDButton
                        arrayOfName={arrayOfName}
                        arrayOfId={arrayOfId}
                        resetSelectedRows={resetSelectedRows}
                    />
                    <DeleteAccountButton
                        arrayOfName={arrayOfName}
                        arrayOfId={arrayOfId}
                        resetSelectedRows={resetSelectedRows}
                    />
                </div>
            </div>
        </Portal>
    );
};
