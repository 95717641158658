import s from './IndeterminateCheckbox.module.scss';
import { HTMLProps, useEffect, useId, useRef } from 'react';
import classNames from 'classnames';
import Check from '@/shared/assets/icons/check.svg';

interface IndeterminateCheckboxProps extends HTMLProps<HTMLInputElement> {
    indeterminate?: boolean;
    isHeader?: boolean;
}

export const IndeterminateCheckbox = (props: IndeterminateCheckboxProps) => {
    const { indeterminate, className, isHeader, ...rest } = props;
    const ref = useRef<HTMLInputElement>(null!);
    const id = useId();

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate]);

    const mods = {
        [s.checked]: rest.checked,
    };

    return (
        <div className={classNames(s.Input, mods, [className])}>
            <label htmlFor={id} className={s.wrapper}>
                <div className={s.checkbox}>
                    {rest.checked &&
                        (isHeader ? (
                            <span className={s.minus}></span>
                        ) : (
                            <Check className={s.check} />
                        ))}
                </div>
            </label>
            <input
                id={id}
                type="checkbox"
                ref={ref}
                className={s.input}
                {...rest}
            />
        </div>
    );
};
