import { Navigate, RouteObject } from 'react-router-dom';
import { NotFound } from '@/pages/NotFound';
import { LoginPage } from '@/pages/LoginPage';

export const noAuthConfig: RouteObject[] = [
    {
        path: '/',
        errorElement: <NotFound />,
        element: <Navigate to="/login" replace />,
    },
    {
        path: 'login',
        element: <LoginPage />,
    },
    {
        path: '*',
        element: <Navigate to={'/login'} />,
    },
];
