import s from './Tooltip.module.scss';
import { ReactNode, useState } from 'react';

interface TooltipProps {
    children: ReactNode;
    value: string;
    isOverflow: boolean;
}

export const Tooltip = (props: TooltipProps) => {
    const { children, value, isOverflow } = props;
    const [active, setActive] = useState(false);

    const onMouseEnter = () => {
        if (!isOverflow) return;
        setActive(true);
    };
    const onMouseLeave = () => {
        if (!isOverflow) return;
        setActive(false);
    };

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={s.wrapper}
        >
            {children}
            {active && value && isOverflow && (
                <span className={s.Tooltip}>{value}</span>
            )}
        </div>
    );
};
