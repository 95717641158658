import s from './NotFound.module.scss';
import { Logo } from '@/shared/ui/Logo/Logo';
import { PageTitle } from '@/shared/ui/PageTitle/PageTitle';

export const NotFound = () => {
    return (
        <div className={s.NotFound}>
            <PageTitle title="404 | VRStrike" />
            <div className={s.popup}>
                <Logo className={s.logo} size={'l'} />
                <span className={s.title}>404</span>
                <span className={s.info}>
                    Что‑то пошло не так.
                    <p>Обновите страницу или повторите попытку позднее.</p>
                </span>
            </div>
        </div>
    );
};
