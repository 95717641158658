import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface PageTitleProps {
    title: string;
}

export const PageTitle = (props: PageTitleProps): null => {
    const { title } = props;
    const location = useLocation();

    useEffect(() => {
        document.title = title;
    }, [location, title]);

    return null;
};
