import classNames from 'classnames';
import s from './Button.module.scss';
import { MouseEvent, ReactNode } from 'react';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';

interface ButtonProps {
    className?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    children?: ReactNode;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    textSize?: 'b2' | 'b3' | 'b4' | 'mobile-b1';
    size?: 'xs' | 's' | 'm';
    width?: 'auto' | 'fill';
    variant?: 'fill' | 'outline';
    color?: 'green' | 'red' | 'red-light';
    maxWidth?: string;
}

export const Button = (props: ButtonProps) => {
    const { isMobile } = useScreenDetector();
    const {
        className,
        children,
        disabled,
        onClick,
        type = 'button',
        textSize = isMobile ? 'mobile-b1' : null,
        width = 'fill',
        variant = 'fill',
        maxWidth = 'none',
        color = 'green',
        size = 'm',
    } = props;

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={classNames(s.Button, {}, [
                className,
                s['width-' + width],
                s['variant-' + variant],
                s['color-' + color],
                s['size-' + size],
                textSize && s[textSize],
            ])}
            type={type}
            style={{ maxWidth }}
        >
            {children}
        </button>
    );
};
