import s from './DeleteCell.module.scss';
import { CellContext } from '@tanstack/react-table';
import { DeleteAccountButton } from '@/features/DeleteAccount';
import { User } from '@/shared/model/types/table';

interface DeleteCellProps<T> {
    row: CellContext<T, unknown>;
}

export const DeleteCell = <T,>(props: DeleteCellProps<T>) => {
    const { row } = props;

    const originalData = row.row.original as User;

    return (
        <div className={s.DeleteCell}>
            <DeleteAccountButton
                arrayOfName={[originalData.name]}
                arrayOfId={[originalData.id]}
                isIcon
            />
        </div>
    );
};
