import { useDispatch } from 'react-redux';
import { alertOpen } from '@/entities/Alert';
import { ActionContent } from '@/entities/Alert/model/slice/alertSlice';

export const useAlert = () => {
    const dispatch = useDispatch();
    return (content: string | ActionContent, isError?: boolean) => {
        dispatch(alertOpen({ content, isError }));
    };
};
