import classNames from 'classnames';
import s from './DateInput.module.scss';
import { ChangeEvent } from 'react';
import Calendar from '@/shared/assets/icons/calendar.svg';
import { IMaskInput } from 'react-imask';

interface InputProps {
    className?: string;
    placeholder?: string;
    error: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    isDirty?: boolean;
}

export const DateInput = (props: InputProps) => {
    const {
        className,
        placeholder,
        error,
        value = '',
        onChange,
        disabled,
    } = props;

    const mods = {
        [s.disabled]: disabled,
        [s.isError]: !!error,
    };

    return (
        <div className={classNames(s.Input, mods, [className])}>
            <IMaskInput
                className={classNames(s.input, [className])}
                value={value}
                mask={Date}
                pattern={'d.m`.`Y'}
                overwrite={true}
                autofix={'pad'}
                onChange={onChange}
                placeholder={placeholder}
                type={'text'}
                disabled={disabled}
                name={'date'}
            />
            <Calendar className={s.icon} />
        </div>
    );
};
