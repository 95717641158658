import { Modal } from '@/shared/ui/Modal/Modal';
import s from './UnbindingHWIDModal.module.scss';
import { Button } from '@/shared/ui/Button/Button';
import { GeneralSelectedI } from '@/shared/model/types/generalSelected';
import { useUnbindingHWIDMutation } from '@/features/UnbindingHWID/api/unbindingHWIDApi';
import { useEffect } from 'react';
import { useError } from '@/shared/lib/hooks/useError';
import { useAlert } from '@/shared/lib/hooks/useAlert';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';

interface UnbindingHWIDModalProps extends GeneralSelectedI {
    onClose: () => void;
    open: boolean;
    resetSelectedRows?: () => void;
}

export const UnbindingHWIDModal = (props: UnbindingHWIDModalProps) => {
    const { open, onClose, arrayOfName, arrayOfId, resetSelectedRows } = props;
    const { isMobile } = useScreenDetector();
    const onAlert = useAlert();
    const [triggerUnbinding, { isSuccess, error, isLoading }] =
        useUnbindingHWIDMutation();

    const onDelete = () => {
        triggerUnbinding(arrayOfId);
    };

    useEffect(() => {
        if (isSuccess) {
            resetSelectedRows && resetSelectedRows();
            onAlert({
                header:
                    arrayOfName.length === 1
                        ? 'Hardware ID пользователя'
                        : 'Hardware ID пользователей:',
                body: arrayOfName,
                footer:
                    arrayOfName.length === 1
                        ? 'успешно отвязан'
                        : 'успешно отвязаны',
            });
            onClose();
        }
    }, [isSuccess]);

    useError(error);

    return (
        <Modal
            onClose={onClose}
            open={open}
            title={'Отвязка Hardware ID'}
            titleSize={'h1'}
        >
            <div className={s.wrapper}>
                {arrayOfName.length === 1 ? (
                    <span className={s.text}>
                        Вы действительно хотите очистить Hardware ID для
                        пользователя{' '}
                        <span className={s.name}>
                            {arrayOfName[0] ? arrayOfName[0] : '-'}
                        </span>
                        ?
                    </span>
                ) : (
                    <span className={s.text}>
                        Вы действительно хотите очистить Hardware ID для
                        пользователей:{' '}
                        {arrayOfName.map((name, index) => {
                            if (index === 9) return '... ';
                            if (index > 9) return;
                            return (
                                <span key={index} className={s.name}>
                                    {name ? name : '-'}
                                    {index < arrayOfName.length - 1 ? ', ' : ''}
                                </span>
                            );
                        })}
                        ?
                    </span>
                )}
            </div>
            <div className={s.actions}>
                <Button
                    color={'red'}
                    onClick={onDelete}
                    disabled={isLoading}
                    size={isMobile ? 's' : 'm'}
                >
                    Отвязать
                </Button>
                <Button
                    color={'red'}
                    variant={'outline'}
                    onClick={onClose}
                    size={isMobile ? 's' : 'm'}
                >
                    Отменить
                </Button>
            </div>
        </Modal>
    );
};
