import { columns } from '@/widgets/UsualTable/model/columns';
import { Table } from '@/entities/Table';
import { SelectedPopup } from '@/entities/SelectedPopup';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { setSelectedArray } from '@/widgets/UsualTable';
import { useCallback, useMemo, useRef } from 'react';
import { Loader } from '@/shared/ui/Loader/Loader';
import s from './UsualTable.module.scss';
import { UsualTableItem } from '@/shared/model/types/table';
import { useGetUserListDataQuery } from '@/entities/User';

export const UsualTable = () => {
    const dispatch = useAppDispatch();
    const { selectedArray } = useAppSelector((state) => state.usualTable);
    const { isLoading, data } = useGetUserListDataQuery('user');
    const tableRef = useRef(null);
    const onSelect = useCallback((data: UsualTableItem[]) => {
        dispatch(setSelectedArray(data));
    }, []);

    const arrayOfName = selectedArray.map((item) => item.fullName);
    const arrayOfId = selectedArray.map((item) => item.id);

    const formattedData: UsualTableItem[] = useMemo(
        () =>
            data?.map((item) => {
                const { licenseStartAt, licenseEndAt, ...rest } = item;
                return {
                    ...rest,
                    licensePeriod: {
                        licenseStartAt: licenseStartAt,
                        licenseEndAt: licenseEndAt,
                    },
                };
            }),
        [data],
    );

    return (
        <>
            {isLoading || !formattedData ? (
                <div className={s.loaderWrapper}>
                    <Loader className={s.loader} />
                </div>
            ) : (
                <Table
                    data={formattedData}
                    columns={columns}
                    onSelect={onSelect}
                    tableRef={tableRef}
                />
            )}
            {!!selectedArray.length && (
                <SelectedPopup
                    arrayOfName={arrayOfName}
                    arrayOfId={arrayOfId}
                    resetSelectedRows={tableRef.current.resetSelectedRows}
                />
            )}
        </>
    );
};
