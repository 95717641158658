import { object, string } from 'yup';

export const step2Schema = object({
    birthDate: string().required(' ').min(10, 'Неверный формат даты'),
    team: string().required(' '),
    nickname: string().required(' '),
    vkLink: string().matches(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:\/?#[\]@!$&'()*+,;=]+$/,
        { message: 'Неккоректная ссылка', excludeEmptyString: true },
    ),
});
