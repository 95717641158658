import { object, string } from 'yup';

export const step1Schema = object({
    fullName: string().required(' '),
    region: string().required(' '),
    phoneNumber: string()
        .required(' ')
        .matches(
            /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/,
            'Неверный формат номера',
        ),
});
