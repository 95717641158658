import React, { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PageLoader } from '@/widgets/PageLoader';
import { routeConfig } from '../lib/routeConfig';
import { noAuthConfig } from '@/app/providers/router/lib/noAuthConfig';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { Alert } from '@/entities/Alert';
import { useGetUserListDataQuery } from '@/entities/User';

const router = createBrowserRouter(routeConfig);
const noAuthRouter = createBrowserRouter(noAuthConfig);

export const AppRouter = () => {
    const { isAuth, isAuthLoading } = useAppSelector((state) => state.app);
    const { isLoading } = useGetUserListDataQuery('licensed_user');
    const { isOpen } = useAppSelector((state) => state.alert);

    return (
        <>
            <Suspense fallback={<PageLoader />}>
                {isAuthLoading || isLoading ? (
                    <PageLoader />
                ) : (
                    <RouterProvider router={isAuth ? router : noAuthRouter} />
                )}
            </Suspense>

            {isOpen && <Alert />}
        </>
    );
};
