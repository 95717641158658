import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsualTableItem } from '@/shared/model/types/table';

interface UsualTableState {
    selectedArray: UsualTableItem[];
}

const initialState: UsualTableState = {
    selectedArray: [],
};

export const usualTableSlice = createSlice({
    name: 'usualTable',
    initialState,
    reducers: {
        setSelectedArray: (state, action: PayloadAction<UsualTableItem[]>) => {
            state.selectedArray = action.payload;
        },
    },
});

export const { setSelectedArray } = usualTableSlice.actions;

export const { reducer: usualTableReducer } = usualTableSlice;
