import { object, string } from 'yup';

export const step3Schema = object({
    login: string().required(' '),
    password: string()
        .required(' ')
        .matches(
            /(?=.*[0-9])(?=.*[!@#$%^&*.,])(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*.,]{6,}/g,
            {
                message: 'Пароль не соответсвует требованиям',
            },
        ),
    confirmPassword: string()
        .required(' ')
        .test(function (value) {
            return this.parent.password !== value
                ? this.createError({
                      message: `Пароли не совпадают`,
                      path: 'confirmPassword',
                  })
                : true;
        }),
});
