import s from './HardwareCell.module.scss';
import { CellContext } from '@tanstack/react-table';
import { UnbindingHWIDButton } from '@/features/UnbindingHWID';
import { User } from '@/shared/model/types/table';

interface HardwareCellProps<T> {
    row: CellContext<T, unknown>;
}

export const HardwareCell = <T,>(props: HardwareCellProps<T>) => {
    const { row } = props;
    const value = row.getValue();
    const originalData = row.row.original as User;

    return (
        <div className={s.wrapper}>
            {value ? (
                <>
                    <span>{String(value)}</span>
                    <UnbindingHWIDButton
                        isIcon
                        arrayOfName={[originalData.name]}
                        arrayOfId={[originalData.id]}
                    />
                </>
            ) : (
                '-'
            )}
        </div>
    );
};
