import classNames from 'classnames';
import s from './IconButton.module.scss';
import { MouseEvent, ReactNode } from 'react';

interface IconButtonProps {
    className?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    children: ReactNode;
    disabled?: boolean;
    size?: 'xs' | 's' | 'm' | 'l';
    color?: 'red' | 'black';
}

export const IconButton = (props: IconButtonProps) => {
    const { className, children, disabled, onClick, color, size = 'm' } = props;

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={classNames(s.IconButton, {}, [
                className,
                s[color],
                s[size],
            ])}
        >
            {children}
        </button>
    );
};
