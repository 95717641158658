import s from './LicensePeriodForm.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { LicensePeriodFormData } from '../model/types/licensePeriod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CellContext } from '@tanstack/react-table';
import { licensePeriodSchema } from '../lib/validationSchema/licensePeriodSchema';
import { DateInput } from '@/shared/ui/DateInput/DateInput';
import {
    dateFormatForBack,
    dateFormatFromBack,
} from '@/shared/lib/utils/dateFormat';
import { LicensePeriod, UsualTableItem } from '@/shared/model/types/table';
import { useEffect } from 'react';
import { useError } from '@/shared/lib/hooks/useError';
import { usePatchUserDataMutation } from '@/entities/User';

interface LicensePeriodFormProps<T> {
    row: CellContext<T, unknown>;
}

export const LicensePeriodForm = <T,>(props: LicensePeriodFormProps<T>) => {
    const { row } = props;

    const [triggerPatch, { isLoading, error }] = usePatchUserDataMutation();
    const { licenseStartAt, licenseEndAt } = row.getValue() as LicensePeriod;

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields, isDirty },
        watch,
    } = useForm<LicensePeriodFormData>({
        resolver: yupResolver(licensePeriodSchema),
        defaultValues: {
            licenseStartAt:
                (licenseStartAt && dateFormatFromBack(licenseStartAt)) || '',
            licenseEndAt:
                (licenseEndAt && dateFormatFromBack(licenseEndAt)) || '',
        },
    });

    const onSubmit: SubmitHandler<LicensePeriodFormData> = (data) => {
        triggerPatch({
            id: (row.row.original as UsualTableItem).id,
            data: {
                licenseStartAt: dateFormatForBack(data.licenseStartAt),
                licenseEndAt: dateFormatForBack(data.licenseEndAt),
            },
        });
    };

    const licenseStartAtWatch = watch('licenseStartAt');
    const licenseEndAtWatch = watch('licenseEndAt');

    useEffect(() => {
        if (
            licenseStartAtWatch.length === 10 &&
            licenseEndAtWatch.length === 10 &&
            isDirty
        ) {
            handleSubmit(onSubmit)();
        }
    }, [licenseEndAtWatch, licenseStartAtWatch]);

    useError(error);

    return (
        <form className={s.LicensePeriodForm} onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="licenseStartAt"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <DateInput
                        disabled={isLoading}
                        value={value}
                        onChange={onChange}
                        placeholder={'__.__.____'}
                        error={errors.licenseStartAt?.message}
                        isDirty={dirtyFields.licenseStartAt}
                    />
                )}
            />
            <span>–</span>
            <Controller
                name="licenseEndAt"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <DateInput
                        disabled={isLoading}
                        value={value}
                        onChange={onChange}
                        placeholder={'__.__.____'}
                        error={errors.licenseEndAt?.message}
                        isDirty={dirtyFields.licenseEndAt}
                    />
                )}
            />
        </form>
    );
};
