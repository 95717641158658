import classNames from 'classnames';
import s from './Logo.module.scss';
import logoIcon from '@/shared/assets/images/logo.svg?url';

type Size = 's' | 'm' | 'l' | 'xl';

interface LogoProps {
    className?: string;
    size?: Size;
}

export const Logo = ({ className, size = 'm' }: LogoProps) => {
    return (
        <div className={classNames(s.Logo, {}, [className, s[size]])}>
            <img src={logoIcon} alt="logo" />
        </div>
    );
};
