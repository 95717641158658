import { rtkApi } from '@/shared/api/rtkApi';

interface Delete {
    status: string;
}

const unbindingHWIDApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        unbindingHWID: build.mutation<Delete, number[]>({
            query: (data) => ({
                url: `/admin/user/unlink-hardware/${data.join(';')}`,
                method: 'PATCH',
            }),
            invalidatesTags: ['UsualTable'],
        }),
    }),
});

export const { useUnbindingHWIDMutation } = unbindingHWIDApi;
