import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useCallback, useMemo, useRef } from 'react';
import { setSelectedArray } from '@/widgets/ClubTable';
import { SelectedPopup } from '@/entities/SelectedPopup';
import { ClubTableItem } from '@/shared/model/types/table';
import { columns } from '@/widgets/ClubTable/model/columns';
import { Table } from '@/entities/Table';
import s from './ClubTable.module.scss';
import { Loader } from '@/shared/ui/Loader/Loader';
import { useGetUserListDataQuery } from '@/entities/User';

export const ClubTable = () => {
    const dispatch = useAppDispatch();
    const { selectedArray } = useAppSelector((state) => state.clubTable);

    const { isLoading, data } = useGetUserListDataQuery('licensed_user');
    const onSelect = useCallback((data: ClubTableItem[]) => {
        dispatch(setSelectedArray(data));
    }, []);
    const tableRef = useRef(null);

    const arrayOfName = selectedArray.map((item) => item.name);
    const arrayOfId = selectedArray.map((item) => item.id);

    const formattedData: ClubTableItem[] = useMemo(
        () =>
            data?.map((item) => {
                const {
                    licenseStartAt,
                    licenseEndAt,
                    fullName,
                    vkLink,
                    qualification,
                    rate,
                    region,
                    team,
                    nickname,
                    phoneNumber,
                    birthDate,
                    dateAuth,
                    email,
                    status,
                    role,
                    ...rest
                } = item;
                return {
                    ...rest,
                    licensePeriod: {
                        licenseStartAt: licenseStartAt,
                        licenseEndAt: licenseEndAt,
                    },
                };
            }),
        [data],
    );

    return (
        <>
            {isLoading || !formattedData ? (
                <div className={s.loaderWrapper}>
                    <Loader className={s.loader} />
                </div>
            ) : (
                <Table
                    data={formattedData}
                    columns={columns}
                    onSelect={onSelect}
                    tableRef={tableRef}
                />
            )}
            {!!selectedArray.length && (
                <SelectedPopup
                    arrayOfName={arrayOfName}
                    arrayOfId={arrayOfId}
                    resetSelectedRows={tableRef.current.resetSelectedRows}
                />
            )}
        </>
    );
};
