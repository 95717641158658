import s from './CreatorRoomCell.module.scss';
import { CellContext } from '@tanstack/react-table';
import { Toggle } from '@/shared/ui/Toggle/Toggle';
import { useError } from '@/shared/lib/hooks/useError';
import { User } from '@/shared/model/types/table';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { usePatchUserDataMutation } from '@/entities/User';

interface CreatorRoomCellProps<T> {
    row: CellContext<T, unknown>;
}

export const CreatorRoomCell = <T,>(props: CreatorRoomCellProps<T>) => {
    const { row } = props;
    const { isMobile } = useScreenDetector();
    const [triggerPatch, { isLoading, error }] = usePatchUserDataMutation();
    const value = Boolean(row.getValue());
    const onToggle = () => {
        triggerPatch({
            id: (row.row.original as User).id,
            data: { creatorRoom: !value },
        });
    };

    useError(error);

    return (
        <div className={s.CreatorRoomCell}>
            <Toggle
                isActive={value}
                onToggle={onToggle}
                disabled={isLoading}
                size={isMobile ? 's' : 'm'}
            />
            {value ? (
                <span className={s.status}>Доступно</span>
            ) : (
                <span className={s.status}>Недоступно</span>
            )}
        </div>
    );
};
