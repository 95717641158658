import s from './Step2.module.scss';
import { Input } from '@/shared/ui/Input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Step2Query, useStep2Mutation } from '../../api/createUsualAccountApi';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Step2Data } from '../../model/types/step2';
import { step2Schema } from '../../lib/validationSchema/Step2Schema';
import { Button } from '@/shared/ui/Button/Button';
import {
    setRegistrationData,
    setRegistrationToken,
} from '@/features/CreateUsualAccountForm';
import { nextStep, prevStep } from '@/entities/CreateUsualAccountStepper';
import { dateFormatForBack } from '@/shared/lib/utils/dateFormat';
import { InputDate } from '@/shared/ui/InputDate/InputDate';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';

export const Step2 = () => {
    const dispatch = useAppDispatch();
    const { isMobile } = useScreenDetector();
    const [triggerStep2, { isLoading, isSuccess, error, data }] =
        useStep2Mutation();

    const {
        data: { birthDate, team, nickname, vkLink },
        registrationToken,
    } = useAppSelector((state) => state.createUsualAccount);

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
        setError,
    } = useForm<Step2Data>({
        resolver: yupResolver(step2Schema),
        defaultValues: {
            birthDate: birthDate || '',
            team: team || '',
            nickname: nickname || '',
            vkLink: vkLink || '',
        },
    });

    const onSubmit: SubmitHandler<Step2Data> = (data) => {
        triggerStep2({
            registrationToken,
            team: data.team,
            vkLink: data.vkLink,
            nickname: data.nickname,
            birthDate: dateFormatForBack(data.birthDate),
        }).then(() => {
            dispatch(
                setRegistrationData({
                    team: data.team,
                    vkLink: data.vkLink,
                    nickname: data.nickname,
                    birthDate: data.birthDate,
                }),
            );
        });
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(setRegistrationToken(data.registrationToken));
            dispatch(nextStep());
        }
    }, [isSuccess]);

    useFormError<Step2Query>(error, setError);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.inputs}>
                <Controller
                    name="birthDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <InputDate
                            value={value}
                            onChange={onChange}
                            label={'Дата рождения*'}
                            placeholder={'Дата рождения*'}
                            error={errors.birthDate?.message}
                            isDirty={dirtyFields.birthDate}
                        />
                    )}
                />
                <Controller
                    name="team"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Команда*'}
                            placeholder={'Введите название'}
                            error={errors.team?.message}
                            isDirty={dirtyFields.team}
                        />
                    )}
                />
                <Controller
                    name="nickname"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Позывной*'}
                            placeholder={'Введите ваш позывной'}
                            error={errors.nickname?.message}
                            isDirty={dirtyFields.nickname}
                        />
                    )}
                />
                <Controller
                    name="vkLink"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Ссылка на VK'}
                            placeholder={'https://vk.com/username'}
                            error={errors.vkLink?.message}
                            isDirty={dirtyFields.vkLink}
                        />
                    )}
                />
            </div>
            <div className={s.actions}>
                <Button
                    onClick={() => dispatch(prevStep())}
                    variant={'outline'}
                    className={s.prev}
                    disabled={isLoading}
                    size={isMobile ? 's' : 'm'}
                >
                    Назад
                </Button>
                <Button
                    type={'submit'}
                    className={s.next}
                    size={isMobile ? 's' : 'm'}
                >
                    Далее
                </Button>
            </div>
        </form>
    );
};
