import {createRoot} from "react-dom/client";
import App from "@/app/App";
import {StoreProvider} from "@/app/providers/StoreProvider/ui/StoreProvider";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<StoreProvider>
		<App/>
	</StoreProvider>);
