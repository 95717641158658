import s from './CreateUsualAccountStepper.module.scss';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import classNames from 'classnames';
import { Step1, Step2, Step3 } from '@/features/CreateUsualAccountForm';

interface RegistrationStepperProps {
    onClose: () => void;
}

export const CreateUsualAccountStepper = (props: RegistrationStepperProps) => {
    const { onClose } = props;

    const { currentStep, totalStep } = useAppSelector(
        (state) => state.createUsualAccountStepper,
    );

    const arrayTotalStep = Array.from({ length: totalStep }, (_, i) => i + 1);

    function getSectionComponent() {
        switch (currentStep) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 onClose={onClose} />;
        }
    }

    return (
        <div>
            <div className={s.progress}>
                <h2 className={s.stepCount}>
                    Шаг {currentStep}/{totalStep}
                </h2>
                <div className={s.progressBar}>
                    {arrayTotalStep.map((item) => (
                        <span
                            key={item}
                            className={classNames(s.tab, {
                                [s.active]: item <= currentStep,
                            })}
                        />
                    ))}
                </div>
            </div>
            {getSectionComponent()}
        </div>
    );
};
