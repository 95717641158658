import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppState {
    isAuth: boolean;
    isAuthLoading: boolean;
}

const initialState: AppState = {
    isAuth: false,
    isAuthLoading: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setIsAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload;
        },
        setIsAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.isAuthLoading = action.payload;
        },
    },
});

export const { setIsAuth, setIsAuthLoading } = appSlice.actions;

export const { reducer: appReducer } = appSlice;
