import s from './BirthDateCell.module.scss';
import { CellContext } from '@tanstack/react-table';
import { dateFormatFromBack } from '@/shared/lib/utils/dateFormat';

interface TextCellProps<T> {
    row: CellContext<T, unknown>;
}

export const BirthDateCell = <T,>(props: TextCellProps<T>) => {
    const { row } = props;
    const value = row.getValue();

    return (
        <div className={s.BirthDateCell}>
            <span>{value ? dateFormatFromBack(String(value)) : '-'}</span>
        </div>
    );
};
