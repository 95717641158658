import { ColumnDef } from '@tanstack/react-table';
import { IndeterminateCheckbox } from '@/entities/Table';
import { HardwareCell } from '@/entities/HardwareCell';
import { DeleteHeader } from '@/entities/DeleteHeader/ui/DeleteHeader';
import { DeleteCell } from '@/entities/DeleteCell/ui/DeleteCell';
import { CreatorRoomCell } from '@/entities/CreatorRoomCell/ui/CreatorRoomCell';
import { LicensePeriodForm } from '@/features/LicensePeriodForm';
import { TextCell } from '@/entities/TextCell';
import { BirthDateCell } from '@/entities/BirthDateCell';
import { LicensePeriod, UsualTableItem } from '@/shared/model/types/table';
import { LinkCell } from '@/entities/LinkCell';
import { TextHeader } from '@/entities/TextHeader';

export const columns: ColumnDef<UsualTableItem>[] = [
    {
        id: 'id',
        header: ({ table }) => (
            <IndeterminateCheckbox
                isHeader
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
        ),
        cell: ({ row }) => (
            <IndeterminateCheckbox
                {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                }}
            />
        ),
    },
    {
        header: 'ФИО',
        cell: (row) => <TextCell row={row} />,
        accessorKey: 'fullName',
    },
    {
        header: 'Логин',
        cell: (row) => <TextCell row={row} />,
        accessorKey: 'name',
    },
    {
        header: () => <TextHeader>Номер телефона</TextHeader>,
        cell: (row) => <TextCell row={row} />,
        accessorKey: 'phoneNumber',
        enableSorting: false,
    },
    {
        header: 'Дата рождения',
        cell: (row) => <BirthDateCell row={row} />,
        accessorKey: 'birthDate',
    },
    {
        header: 'Регион регистрации',
        cell: (row) => <TextCell row={row} />,
        accessorKey: 'region',
    },
    {
        header: 'Команда',
        cell: (row) => <TextCell row={row} />,
        accessorKey: 'team',
    },
    {
        header: 'Позывной',
        cell: (row) => <TextCell row={row} />,
        accessorKey: 'nickname',
    },
    {
        header: () => <TextHeader>Ссылка на VK</TextHeader>,
        cell: (row) => <LinkCell row={row} />,
        accessorKey: 'vkLink',
        enableSorting: false,
    },
    {
        header: 'Разряд',
        cell: (row) => <TextCell row={row} />,
        accessorKey: 'rate',
    },
    {
        header: 'Квалификация',
        cell: (row) => <TextCell row={row} />,
        accessorKey: 'qualification',
    },
    {
        header: 'Статус',
        cell: (row) => <TextCell row={row} />,
        accessorKey: 'status',
    },
    {
        header: () => <TextHeader>Hardware ID</TextHeader>,
        cell: (row) => <HardwareCell row={row} />,
        accessorKey: 'hardwareId',
        enableSorting: false,
    },
    {
        header: 'Создатель комнаты',
        cell: (row) => <CreatorRoomCell row={row} />,
        accessorKey: 'creatorRoom',
    },
    {
        header: 'Срок действия',
        cell: (row) => <LicensePeriodForm row={row} />,
        accessorKey: 'licensePeriod',
        sortingFn: (rowA, rowB, columnId) => {
            const endDataA = new Date(
                (rowA.getValue(columnId) as LicensePeriod).licenseEndAt,
            ).getTime();
            const endDataB = new Date(
                (rowB.getValue(columnId) as LicensePeriod).licenseEndAt,
            ).getTime();

            return endDataA > endDataB ? 1 : -1;
        },
    },
    {
        header: () => <DeleteHeader />,
        cell: (row) => <DeleteCell row={row} />,
        accessorKey: 'delete',
        enableSorting: false,
    },
];
