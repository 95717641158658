import { rtkApi } from '@/shared/api/rtkApi';

interface Delete {
    status: string;
}

const deleteAccountApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        deleteAccount: build.mutation<Delete, number[]>({
            query: (data) => ({
                url: `/admin/user/${data.join(';')}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['UsualTable'],
        }),
    }),
});

export const { useDeleteAccountMutation } = deleteAccountApi;
