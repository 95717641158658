import classNames from 'classnames';
import s from './Toggle.module.scss';

interface ToggleProps {
    className?: string;
    isActive: boolean;
    onToggle: () => void;
    disabled: boolean;
    size?: 's' | 'm';
}

export const Toggle = (props: ToggleProps) => {
    const { className, isActive, onToggle, disabled, size = 'm' } = props;

    const mods = {
        [s.active]: isActive,
    };

    return (
        <button
            disabled={disabled}
            className={classNames(s.Toggle, mods, [className, s[size]])}
            onClick={onToggle}
        >
            <div className={s.dot}></div>
        </button>
    );
};
