import s from './TextHeader.module.scss';

interface TextHeaderProps {
    children?: string;
}

export const TextHeader = (props: TextHeaderProps) => {
    const { children } = props;
    return <div className={s.TextHeader}>{children}</div>;
};
