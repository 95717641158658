export const dateFormatFromBack = (date: string) => {
    return date?.split('-').reverse().join('.');
};
export const dateFormatForBack = (date: string) => {
    return date?.split('.').reverse().join('-');
};

export const dateParse = (date: string) => {
    return Date.parse(date?.split('.').reverse().join('/'));
};
