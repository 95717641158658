import { useState } from 'react';
import { Button } from '@/shared/ui/Button/Button';
import s from './DeleteAccountButton.module.scss';
import Trash from '@/shared/assets/icons/trash.svg';
import { DeleteAccountModal } from '../DeleteAccountModal/DeleteAccountModal';
import { GeneralSelectedI } from '@/shared/model/types/generalSelected';
import { IconButton } from '@/shared/ui/IconButton/IconButton';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';

interface DeleteAccountButtonProps extends GeneralSelectedI {
    isIcon?: boolean;
    resetSelectedRows?: () => void;
}

export const DeleteAccountButton = (props: DeleteAccountButtonProps) => {
    const { arrayOfName, arrayOfId, isIcon, resetSelectedRows } = props;
    const [open, setOpen] = useState(false);
    const { isMobile } = useScreenDetector();

    return (
        <>
            {isIcon ? (
                <IconButton
                    size={isMobile ? 'xs' : 'm'}
                    color={'red'}
                    onClick={() => setOpen(true)}
                >
                    <Trash />
                </IconButton>
            ) : (
                <Button
                    onClick={() => setOpen(true)}
                    color={'red'}
                    width={'fill'}
                    size={'xs'}
                >
                    <div className={s.buttonContent}>
                        <span>Удалить</span>
                        <Trash className={s.icon} />
                    </div>
                </Button>
            )}

            {open && (
                <DeleteAccountModal
                    arrayOfName={arrayOfName}
                    arrayOfId={arrayOfId}
                    open={open}
                    onClose={() => setOpen(false)}
                    resetSelectedRows={resetSelectedRows}
                />
            )}
        </>
    );
};
