import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

export const noAuthApi = createApi({
    reducerPath: 'noAuthApi',
    baseQuery: fetchBaseQuery({
        baseUrl: __API__,
    }) as BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    endpoints: (builder) => ({}),
});
