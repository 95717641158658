import s from './LinkCell.module.scss';
import { CellContext } from '@tanstack/react-table';
import { useRef } from 'react';
import { useIsOverflow } from '@/shared/lib/hooks/useIsOverflow';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';

interface TextCellProps<T> {
    row: CellContext<T, unknown>;
}

export const LinkCell = <T,>(props: TextCellProps<T>) => {
    const { row } = props;
    const value = row.getValue();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const isOverflow = useIsOverflow(wrapperRef);
    return (
        <Tooltip value={String(value)} isOverflow={isOverflow}>
            <div className={s.LinkCell} ref={wrapperRef}>
                {value ? (
                    <a href={String(value)} target="_blank">
                        {String(value)}
                    </a>
                ) : (
                    <span>-</span>
                )}
            </div>
        </Tooltip>
    );
};
