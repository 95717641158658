import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CreateUsualAccountState {
    data: {
        fullName: string;
        region: string;
        phoneNumber: string;
        birthDate: string;
        team: string;
        nickname: string;
        vkLink: string;
    };
    registrationToken: string;
}

const initialState: CreateUsualAccountState = {
    data: {
        phoneNumber: '',
        region: '',
        fullName: '',
        birthDate: '',
        team: '',
        nickname: '',
        vkLink: '',
    },
    registrationToken: null,
};

export const createUsualAccountSlice = createSlice({
    name: 'createUsualAccount',
    initialState,
    reducers: {
        setRegistrationData: (
            state,
            action: PayloadAction<Partial<CreateUsualAccountState>>,
        ) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
        setRegistrationToken: (state, action: PayloadAction<string>) => {
            state.registrationToken = action.payload;
        },

        resetRegistrationData: () => initialState,
    },
});

export const {
    setRegistrationData,
    resetRegistrationData,
    setRegistrationToken,
} = createUsualAccountSlice.actions;

export const { reducer: createUsualAccountReducer } = createUsualAccountSlice;
