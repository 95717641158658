import s from './Step1.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@/shared/ui/Input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Step1Query, useStep1Mutation } from '../../api/createUsualAccountApi';
import {
    setRegistrationData,
    setRegistrationToken,
} from '@/features/CreateUsualAccountForm';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useEffect } from 'react';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { InputTel } from '@/shared/ui/InputTel/InputTel';
import { Step1Data } from '@/features/CreateUsualAccountForm/model/types/step1';
import { step1Schema } from '../../lib/validationSchema/Step1Schema';
import { Button } from '@/shared/ui/Button/Button';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { nextStep } from '@/entities/CreateUsualAccountStepper';

export const Step1 = () => {
    const dispatch = useAppDispatch();
    const { isMobile } = useScreenDetector();
    const [triggerStep1, { isLoading, isSuccess, error, data }] =
        useStep1Mutation();

    const {
        data: { phoneNumber, region, fullName },
    } = useAppSelector((state) => state.createUsualAccount);

    const {
        control,
        handleSubmit,
        formState: { errors, dirtyFields },
        setError,
    } = useForm<Step1Data>({
        resolver: yupResolver(step1Schema),
        defaultValues: {
            fullName: fullName || '',
            region: region || '',
            phoneNumber: phoneNumber || '',
        },
    });

    const onSubmit: SubmitHandler<Step1Data> = (data) => {
        triggerStep1({
            phoneNumber: data.phoneNumber,
            fullName: data.fullName,
            region: data.region,
        }).then(() => {
            dispatch(
                setRegistrationData({
                    phoneNumber: data.phoneNumber,
                    fullName: data.fullName,
                    region: data.region,
                }),
            );
        });
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(setRegistrationToken(data.registrationToken));
            dispatch(nextStep());
        }
    }, [isSuccess]);

    useFormError<Step1Query>(error, setError);

    return (
        <div className={s.wrapper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={s.inputs}>
                    <Controller
                        name="fullName"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                label={'ФИО*'}
                                placeholder={'Введите ваше ФИО'}
                                error={errors.fullName?.message}
                                isDirty={dirtyFields.fullName}
                            />
                        )}
                    />
                    <Controller
                        name="region"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                label={'Регион регистрации*'}
                                placeholder={'Введите регион'}
                                error={errors.region?.message}
                                isDirty={dirtyFields.region}
                            />
                        )}
                    />
                    <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputTel
                                value={value}
                                onChange={onChange}
                                label={'Номер телефона'}
                                placeholder={'+7 900 000-00-00'}
                                error={errors.phoneNumber?.message}
                                isDirty={dirtyFields.phoneNumber}
                                baseAddon={false}
                            />
                        )}
                    />
                </div>
                <Button
                    type={'submit'}
                    className={s.next}
                    size={isMobile ? 's' : 'm'}
                    disabled={isLoading}
                >
                    Далее
                </Button>
            </form>
        </div>
    );
};
