import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import s from './Table.module.scss';
import classNames from 'classnames';
import ArrowDown from '@/shared/assets/icons/arrow_down.svg';
import { memo, MutableRefObject, useEffect, useImperativeHandle } from 'react';

interface TableProps<T> {
    data: T[];
    columns: ColumnDef<T>[];
    onSelect: (data: T[]) => void;
    tableRef: MutableRefObject<any>;
}

const Table = <T,>(props: TableProps<T>) => {
    const { data, columns, onSelect, tableRef } = props;

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const selectedArray = Object.keys(table.getState().rowSelection);

    const selectedRowData = table
        .getSelectedRowModel()
        .rows.map((row) => row.original);

    useEffect(() => {
        onSelect(selectedRowData);
    }, [selectedRowData]);
    useImperativeHandle(tableRef, () => ({
        resetSelectedRows: () => table.toggleAllRowsSelected(false),
    }));
    return (
        <table className={s.table}>
            <thead className={s.thead}>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className={s.tr}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id} className={s.th}>
                                {header.isPlaceholder ? null : (
                                    <div
                                        {...{
                                            className:
                                                header.column.getCanSort()
                                                    ? s.sortHeader
                                                    : '',
                                            onClick:
                                                header.column.getToggleSortingHandler(),
                                        }}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                        {{
                                            asc: (
                                                <ArrowDown className={s.icon} />
                                            ),
                                            desc: (
                                                <ArrowDown
                                                    className={
                                                        s.icon + ' ' + s.revert
                                                    }
                                                />
                                            ),
                                        }[
                                            header.column.getIsSorted() as string
                                        ] ?? null}
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className={s.tbody}>
                {table.getRowModel().rows.map((row) => (
                    <tr
                        key={row.id}
                        className={classNames(s.tr, {
                            [s.active]: selectedArray.includes(row.id),
                        })}
                    >
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className={s.td}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext(),
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default memo(Table) as typeof Table;
