import { rtkApi } from '@/shared/api/rtkApi';
import { User } from '@/shared/model/types/table';

interface PatchUser {
    id: number;
    data: {
        creatorRoom?: boolean;
        licenseStartAt?: string;
        licenseEndAt?: string;
    };
}

interface PatchUserDataResponse {
    status: string;
}

type Role = 'licensed_user' | 'user' | 'admin';

const userApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getUserListData: build.query<User[], Role>({
            query: (role) => `/admin/user/list?role=${role}`,
            providesTags: ['UsualTable', 'ClubTable'],
        }),
        patchUserData: build.mutation<PatchUserDataResponse, PatchUser>({
            query: (data) => ({
                url: `/admin/user/${data.id}`,
                method: 'PATCH',
                body: data.data,
            }),
            invalidatesTags: ['UsualTable', 'ClubTable'],
        }),
    }),
});

export const { useGetUserListDataQuery, usePatchUserDataMutation } = userApi;
