import { useEffect } from 'react';
import {
    isApiInputsResponse,
    isApiResponse,
} from '@/shared/lib/utils/typeChecker';
import { useAlert } from '@/shared/lib/hooks/useAlert';

export const useError = (error: any) => {
    const onAlert = useAlert();
    useEffect(() => {
        if (error)
            if (isApiResponse(error)) {
                onAlert(error.data.details.message, true);
            } else onAlert('Произошла неизвестная ошибка', true);
    }, [error]);
};
