import classNames from 'classnames';
import { FC } from 'react';
import s from'./Loader.module.scss';

interface LoaderProps {
    className?: string;
}

export const Loader: FC<LoaderProps> = (props) => {
    const { className } = props;

    return <span className={classNames(s.loader, {}, [className])} />;
};
