import './styles/index.scss';
import { Suspense } from 'react';
import { AppRouter } from '@/app/providers/router';

const App = () => {
    return (
        <Suspense>
            <AppRouter />
        </Suspense>
    );
};

export default App;
