import s from './DraggableButton.module.scss';
import Move from '@/shared/assets/icons/move.svg';
import { MutableRefObject, useEffect, useRef } from 'react';

interface DraggableButtonProps {
    popupRef: MutableRefObject<HTMLDivElement>;
}

export const DraggableButton = (props: DraggableButtonProps) => {
    const { popupRef } = props;

    const handlerRef = useRef<HTMLDivElement>(null);
    const isClicked = useRef<boolean>(false);

    const coords = useRef<{
        startY: number;
        lastY: number;
    }>({
        startY: 0,
        lastY: 0,
    });

    useEffect(() => {
        if (!popupRef.current || !handlerRef.current) return;

        const popup = popupRef.current;
        const handler = handlerRef.current;

        const onTouchStart = (e: TouchEvent) => {
            e.preventDefault();
            isClicked.current = true;
            if (!coords.current.lastY) {
                coords.current.lastY = e.touches[0].clientY - 22;
            }
            coords.current.startY = e.touches[0].clientY;
        };

        const onTouchEnd = () => {
            isClicked.current = false;
            coords.current.lastY = popup.offsetTop;
        };

        const onTouchMove = (e: TouchEvent) => {
            if (!isClicked.current) return;

            const nextY =
                e.touches[0].clientY -
                coords.current.startY +
                coords.current.lastY;

            if (nextY > 0 && window.innerHeight - 106 > nextY) {
                popup.style.top = `${nextY}px`;
            }
        };

        handler.addEventListener('touchstart', onTouchStart);
        handler.addEventListener('touchend', onTouchEnd);
        document.addEventListener('touchmove', onTouchMove);
        document.addEventListener('touchcancel', onTouchEnd);

        return () => {
            handler.removeEventListener('touchstart', onTouchStart);
            handler.removeEventListener('touchend', onTouchEnd);
            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('touchcancel', onTouchEnd);
        };
    }, []);
    return (
        <div className={s.DraggableButton} ref={handlerRef}>
            <Move />
        </div>
    );
};
