import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Status = 'success' | 'error' | null;

interface StatusModalState {
    status: Status;
    name: string;
}

const initialState: StatusModalState = {
    status: null,
    name: '',
};

export const statusModalSlice = createSlice({
    name: 'statusModalAccount',
    initialState,
    reducers: {
        setSuccessStatusModal: (state, action: PayloadAction<string>) => {
            state.status = 'success';
            state.name = action.payload;
        },
        setErrorStatusModal: (state, action: PayloadAction<string>) => {
            state.status = 'error';
            state.name = action.payload;
        },
        onClose: (state) => {
            state.status = null;
            state.name = '';
        },
    },
});

export const { setSuccessStatusModal, setErrorStatusModal, onClose } =
    statusModalSlice.actions;

export const { reducer: statusModalReducer } = statusModalSlice;
