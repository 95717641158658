import { rtkApi } from '@/shared/api/rtkApi';

export interface Step1Query {
    phoneNumber: string;
    fullName: string;
    region: string;
}

export interface Step2Query {
    registrationToken: string;
    birthDate: string;
    team: string;
    nickname: string;
    vkLink: string;
}

export interface Step3Query {
    registrationToken: string;
    login: string;
    password: string;
}

interface TokenResponse {
    registrationToken: string;
}

const createUsualAccountApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        step1: build.mutation<TokenResponse, Step1Query>({
            query: (data) => ({
                url: '/admin/registration/step-1',
                method: 'POST',
                body: data,
            }),
        }),
        step2: build.mutation<TokenResponse, Step2Query>({
            query: (data) => ({
                url: '/admin/registration/step-2',
                method: 'POST',
                body: data,
            }),
        }),
        step3: build.mutation<void, Step3Query>({
            query: (data) => ({
                url: '/admin/registration/step-3',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['UsualTable'],
        }),
    }),
});

export const { useStep1Mutation, useStep2Mutation, useStep3Mutation } =
    createUsualAccountApi;
